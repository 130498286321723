import React from 'react';

import './PortofolioItemCard.scss';

const PortofolioItemCard = ({ title, subtitle, id, image_url}) => {
	return (
	<article className="article-port__body">
		<div className="article-port__body-container-port">
			<section className="article-port__body-container-port-main">
				<header>
					<img src={image_url} alt="portofolio background"/>
				</header>
				<article>
					<h3>{title}</h3>
					<span>{subtitle}</span>
				</article>
			</section>
		</div>
	</article>
	);
}

export default PortofolioItemCard;