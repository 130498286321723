const BASE_URL = 'https://backend.janariglobal.co.id/api';

const API_ENDPOINT = {
  client: `${BASE_URL}/client`,
  contact: `${BASE_URL}/contact`,
  blogs: `${BASE_URL}/content`,
  portofolio: `${BASE_URL}/portofolio`,
  send_mail: `${BASE_URL}/send_mail`,
  send_pelaporan: `${BASE_URL}/send_pelaporan`,
};

export default API_ENDPOINT
