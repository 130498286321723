import React from 'react';
import { useLocation } from "react-router-dom";
import PromotionCard from '../../components/PromotionCard/PromotionCard';
import HeroOurServices from "../../components/HeroOurServices/HeroOurServices";
import OurServicesItem from "../../components/OurServicesItem/OurServicesItem";

import crisisManagementImage from '../../assets/background/service2.png';
import DigitalManagementImage from '../../assets/background/service3.png';
import CreativeManagementImage from '../../assets/background/service4.png';
import EventManagementImage from '../../assets/background/service5.png';
import ItDevelopmentImage from '../../assets/background/it-development.png';

import Footer from "../../components/Footer/Footer";

const OurServices = () => {
  const location = useLocation();
  const data = location.state?.service;

  React.useEffect(() => {
    if (window.location.hash.trim()) {
      window.location.href = data || window.location.hash;
    }
  }, [data]);

  return (
    <>
      {/*<Swiper*/}
      {/*  slidesPerView={1}*/}
      {/*  direction="vertical"*/}
      {/*  className="swiper-services"*/}
      {/*>*/}
      {/*  <SwiperSlide>*/}
      <section className="our-services__container">
        <HeroOurServices />

        {/*id="integration-communication"*/}
        <OurServicesItem
          position="right"
          title="IT Development"
          id="it-development"
          paragraph={
            <p>We have a creative IT team so we can provide services that suit your needs.</p>
          }
          listItem={[
            {
              title: "IT Development",
              subList: [
                "Website Development",
                "Apps Development",
                "Automation Batch Scheduler/Robotic",
                "Digital Experiences Platform",
                "System Development",
                "IT Consultant",
                "Integrated Security System",
                "UI & UX Design",
              ]
            }
          ]}
          background={ItDevelopmentImage}
        />

        <OurServicesItem
            position="left"
            title="Social Media Managament"
            id="social-media-management"
            paragraph={
              <>
                <p>
                  Social Media Communication can manage the dissemination of business and company
                  information, as well as manage the company's image to external audiences.
                </p>
                <p>
                  Social Media Management can help create and manage digital assets for you.
                </p>
              </>
            }
            listItem={[
              {
                title: "Social Media Maintenance",
                subList: [
                  'Digital Campaign',
                  'Digital Branding',
                  'Digital Marketing',
                  'Integrated Social Media & Portal Media',
                ],
              },
              {
                title: "Social Media Communication",
                subList: [
                  "SMS & E-Mail Blast",
                  "Multichannel Blast",
                  "WhatsApp Blast",
                  "Automated Self Services",
                  "Intelligent Assistant",
                  "Janari Chat",
                  "Rumah janari",
                ]
              }
            ]}
            background={DigitalManagementImage}
          />
        {/*</SwiperSlide>*/}

        {/*<SwiperSlide>*/}
          <OurServicesItem
            position="right"
            title="Creative Management"
            id="creative-management"
            paragraph={
              <>
                <p>Communication can be more interesting if it is collaborated
                with good creative management</p>
                <p>So that Creative Management can produce program results that are more attractive and easy to understand in embodying
                information through visual forms</p>
              </>
            }
            listItem={[
              {
                title: "Creative Campaign",
                subList: [
                  "Brand Campaign",
                  "Creative Campaign",
                  "TV/Digital Commercial",
                  "OOH/DOOH Media",
                ]
              },
              {
                title: "Creative Branding",
                subList: [
                  "Brand DNA",
                  "Brand Guideline",
                  "Logo Design",
                  "Collateral & Sales Material",
                ]
              }
            ]}
            background={CreativeManagementImage}
          />
        {/*</SwiperSlide>*/}

        {/*<SwiperSlide>*/}
          <OurServicesItem
            position="left"
            title="Event-Management"
            id="event-management"
            paragraph={
              <p>
                Event Management can be a solution for you to convey communication in the form of an experience that is in line with your goals
              </p>
            }
            listItem={[
              {
                title: "Digital Activation",
                subList: [
                  "Virtual Event",
                  "Broadcasting Webinar",
                  "Hybrid Event",
                  "Online Sales Activation",
                ]
              },
              {
                title: "On Ground Activation",
                subList: [
                  "Brand Activation",
                  "M.I.C.E & Training",
                  "Promotor Partnership",
                  "Event Contractor & Production"
                ]
              }
            ]}
            background={EventManagementImage}
          />
        {/*</SwiperSlide>*/}

        {/*<SwiperSlide>*/}
          <OurServicesItem
            position="right"
            title="Crisis Management"
            id="crisis-management"
            paragraph={
              <p>
                Crisis management as a preventive measure or strategy to optimize
                the management of communication crises that occur in your company or business.
              </p>
            }
            listItem={[
              {
                title: "Data Analyzing",
                subList: [
                  "Data Analytics",
                  "Digital Analytics",
                  "Campaign Analytics",
                  "Integrations Analytics",
                ],
              },
              {
                title: "Crisis Solving",
                subList: [
                  "Media Listening",
                  "Sentiment Detection",
                  "Investigation Issue",
                  "Spread Messages",
                  "PR Statement"
                ]
              }
            ]}
            background={crisisManagementImage}
          />
        <div style={{margin: '6rem 0', scrollSnapAlign: 'center'}}>
          <PromotionCard />
        </div>
        <footer style={{scrollSnapAlign: 'center'}}>
          <Footer />
        </footer>
      </section>
        {/*</SwiperSlide>*/}
    </>
  );
};

export default OurServices;