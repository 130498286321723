import React from "react";
import axios from 'axios';
import API_ENDPOINT from "../../globals/apiEndpoint";

import "./FormContact.scss";
import gedung from "../../assets/background/gedung.png";
import { ArrowIcon } from '../../assets/icons';

const FormContact = () => {
    const [isMessageSent, setIsMessageSent] = React.useState(false);
    const name = React.useRef();
    const email = React.useRef();
    const message = React.useRef();

    const submitHandler = async (event) => {
        event.preventDefault();

        const enteredData = {
            name: name.current.value,
            email: email.current.value,
            body: message.current.value,
        };

        try {
            await axios.post(API_ENDPOINT.send_mail, enteredData);
            name.current.value = "";
            email.current.value = "";
            message.current.value = "";
            setIsMessageSent(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="container mb-3">
                <div className="d-flex justify-content-between contact-content">
                    <div className="wrapper">
                        <img className="gedung-img" src={gedung} alt="gedung" />
                    </div>
                    <div className="wrapper">
                        <h1 className="font-green font-green__title">Headquarters</h1>
                        <p className="font-green">
                            GKM Tower, Level 20
                            Jl. TB  Simatupang No. Kav 89B Kebagusan, Pasar Minggu, Jakarta Selatan, DKI Jakarta 12520
                        </p>
                        <div className="form__container">
                            <form onSubmit={submitHandler} className="form-contact">
                                {/* name */}
                                <div className="mb-3">
                                    <input ref={name} type="text" className="custom-form form-control form-control-lg" id="name" aria-describedby="nameHelp" placeholder="Name" />
                                </div>
                                {/* Email */}
                                <div className="mb-3">
                                    <input ref={email} type="email" className="custom-form form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="Email Address" />
                                </div>
                                {/* Message */}
                                <div className="mb-3">
                                    <input ref={message} type="text" className="custom-form form-control form-control-lg" id="message" aria-describedby="messageHelp" placeholder="How can we help?" />
                                </div>
                                <div className="mb-3">
                                    <button type="submit" className="submit btn-success btn d-flex justify-content-between align-items-center">Submit <span>
                                        <ArrowIcon />
                                    </span></button>
                                </div>
                                {isMessageSent && <h3 className="message-successfully">Message has been sent</h3>}
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default FormContact;