import React from 'react';

import BlogsContainer from "../../components/BlogsContainer/BlogsContainer";
import MessageAboutUs from "../../components/MessageAboutUs/MessageAboutUs";
import PromotionCard from "../../components/PromotionCard/PromotionCard";
import Footer from "../../components/Footer/Footer";

const Blogs = () => {
  return (
    <>
      <div style={{
        height: '800px',
        width: '100%',
        backgroundColor: '#EAF3ED',
        top: '0',
        zIndex: '-20',
        position: 'absolute'

      }}></div>
      <div style={{marginTop: "7rem"}}>
        <BlogsContainer count={6} />
      </div>
      <MessageAboutUs />
      <PromotionCard/>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Blogs;
