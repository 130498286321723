import React from "react";
import axios from  "axios";
import Link from "react-dom";

import PortofolioItemCard from "../PortofolioItemCard/PortofolioItemCard";

import {ArrowIcon, Dots} from "../../assets/icons";
import API_ENDPOINT from "../../globals/apiEndpoint";

import './PortofolioContainer.scss';

const delay = 2500;
const PortofolioContainer = ({ isPreview }) => {
	const [ portos, setPorto ] = React.useState([]);
	const [ isLoading, setIsLoadsing ] = React.useState(true);
	const [slideIndex, setSlideIndex] = React.useState(0)
	const timeoutRef = React.useRef(null);

	const resetTimeout = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	React.useEffect( () => {
		const fetchPorto = async () => {
			try {
				const portoResponse = await axios.get(API_ENDPOINT.portofolio);
				setPorto(portoResponse.data.data);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoadsing(false);
			}
		}
		fetchPorto();
	}, []);


	const content = isPreview ? portos.slice(0, 4) : portos;

	React.useEffect(() => {
		resetTimeout()
		timeoutRef.current = setTimeout(() =>
			setSlideIndex((prevIndex) =>
				prevIndex === content.length - 1 ? 0 : prevIndex + 1
			),
			delay
		);

		return () => {
			resetTimeout();
		};
	}, [slideIndex])


	return (
		<section className="blogsHomePage container">
			<header className="d-flex flex-column flex-md-row justify-content-between">
				<h2>Our Portofolio</h2>
				{isLoading && !isPreview && (
					<h2 className="text-center">Loading...</h2>
				)}
			</header>
			<div className="blogsHomePage__container-port">
				<div className="slideShowSlider" style={{ transform: `translate3d(${-slideIndex * 100}%, 0, 0)` }}>
					{portos.map((porto, index) => (
						<PortofolioItemCard
							key={index + 1}
							title={porto.title}
							subtitle={porto.subtitle}
							id={index + 1}
							image_url={porto.image_url}
						/>
					))}
				</div>
				<div className="slideshowDots">
					{portos.map((_, idx) => (
						<div key={idx} className={`slideshowDot${slideIndex === idx ? " active" : ""}`}></div>
					))}
				</div>
			</div>
		</section>
	);
}

export default PortofolioContainer;