import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo/logo.svg';

import './Navbar.scss';

const Navbar = () => {
  const removeCollapseHandler = () => {
    document.querySelector('.navbar-collapse').classList.remove('show');
  }

  return (
    <section className="nav">
      <nav className="navbar navbar-expand-lg mx-auto w-100">
        <div className="container-fluid">
          <Link to="/" onClick={removeCollapseHandler}>
            <img src={Logo} alt="logo bantu kerjaanmu" width="50"/>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" onClick={removeCollapseHandler}>Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/about-us" onClick={removeCollapseHandler}>Company</Link>
              </li>
              <li className="nav-item">
                <Link to="/services" onClick={removeCollapseHandler}>Services</Link>
              </li>
              <li className="nav-item">
                <Link to="/blogs" onClick={removeCollapseHandler}>Blog</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" onClick={removeCollapseHandler}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Navbar;