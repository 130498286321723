import React from 'react';
import {EventIcon, HandIcon, LaptopIcon, MarineIcon, PhoneIcon} from "../../assets/icons";
import './HeroOurServices.scss';

import serviceImage from '../../assets/background/services.png';

const HeroOurServices = () => {
  let content = [];
  for (let i = 1; i <= 9; i++) {
    let classes;
    if (i <= 3) {
      classes = 'first';
    } else if (i <= 6) {
      classes = 'second';
    } else {
      classes = 'third';
    }

    let svgIcon;
    if (i === 2) {
      svgIcon =  (
        <a href="#social-media-management">
          <PhoneIcon />
        </a>
      );
    } else if (i === 4) {
      svgIcon = (
        <a href="#crisis-management">
          <HandIcon />
        </a>
      );
    } else if (i === 5) {
      svgIcon = (
        <a href="#integration-communication">
          <MarineIcon />
        </a>
      );
    } else if (i === 6) {
      svgIcon = (
        <a href="#creative-management">
          <LaptopIcon />
        </a>
      );
    } else if (i === 8) {
      svgIcon = (
        <a href="#event-management">
          <EventIcon />
        </a>
      );
    }

    content.push(
      <div key={i} className={`services__wheel-item wheel__item-${classes}`}>
        <div className="content">
          <div className="content-inside">
            {/*<MarineIcon />*/}
            {svgIcon}
          </div>
        </div>
      </div>
    )
  }

  return (
    <section
      className="hero__ourServices">
      <div className="hero__ourServices__container container mx-auto row flex-column-reverse flex-lg-row align-items-center g-5 justify-content-center">
        <div id="integration-communication" data-aos="fade-right" className="col-lg-6 p-0 m-0">
          <h1 className="display-5 fw-bold lh-1">Our <br /> <span>Services</span></h1>
          <p>A communication program integrated and can be a strategy for your company to create integrated internal and external communication</p>
        </div>
        <div data-aos="fade-left" className="hero__ourServices-wheel col-10 col-sm-8 col-lg-6">
          <div
            className="services__wheel"
            style={{backgroundImage: `url(${serviceImage})`}}
          >
            {content}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroOurServices;