import React from 'react';
import Tilt from "react-parallax-tilt";
import hand from '../../assets/icons/hand.png';
import ball1 from '../../assets/icons/ball-1.png';
import ball2 from '../../assets/icons/ball-2.png';
import ball3 from '../../assets/icons/ball-3.png';
import ball4 from '../../assets/icons/ball-4.png';
import ball5 from '../../assets/icons/ball-5.png';
import './JanariValues.scss';

import janariData from "../../data/janariValues";
import {CSSTransition} from "react-transition-group";

const JanariValues = () => {
  const [activeValue, setActiveValue] = React.useState({
    title: 'Trusted',
    description: 'As a communication integration service provider, we have been trusted by various companies to provide communication and information management solutions',
  });

  const [activeValueId, setActiveValueId] = React.useState('value-1');
  const [enter, setEnter] = React.useState(true);

  const changeActiveValue = (value) => {
    setActiveValue({
      ...janariData[value],
      }
    );
    setEnter(true);
  }

  const onMouseLeaveHandler = () => {
    setEnter(false);
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      let newActiveValueId;
      if (activeValueId === 'value-5') {
        newActiveValueId = 'value-1';
      } else {
        newActiveValueId = `value-${parseInt(activeValueId[activeValueId.length - 1]) + 1}`;
      }

      setActiveValueId(newActiveValueId);
      setActiveValue({
        ...janariData[newActiveValueId],
      });
    }, 5000);

    const timeout = setTimeout(() => {
      setEnter(true);
    });

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
      setEnter(false);
    };
  }, [activeValueId]);

  return (
    <section className="jaraniValues--bg">
      <section
        className="jaraniValues container ">
        <div className="jaraniValues__container row mx-auto row flex-column-reverse flex-lg-row-reverse justify-content-between align-items-center">
          <div data-aos="zoom-in" className="jaraniValues__description col-10 col-sm-8 col-lg-6 align-self-center">
            <div className="service-content">
              <h2 className="display-5 fw-bold lh-1 mb-3 ">Why Janari?</h2>
              <CSSTransition
                in={enter}
                timeout={500}
                classNames="janari-value-transition"
              >
                <div>
                  <h3>{activeValue.title}</h3>
                  <p>{activeValue.description}</p>
                </div>
              </CSSTransition>
            </div>
          </div>
          <div className="wrapper-ball col-12 col-lg-5">
            <Tilt perspective={3000}>
              <div className="wrapper-ball__container">
                <div
                  style={{
                    backgroundImage: `url(${ball1})`,
                  }}
                  onMouseLeave={onMouseLeaveHandler}
                  onMouseEnter={changeActiveValue.bind(null, 'value-1')}
                  className={`wrapper-ball__item ${activeValueId === 'value-1' ? 'active-value' : ''}`}
                ></div>
                <div
                  style={{
                    backgroundImage: `url(${ball2})`,
                  }}
                  onMouseLeave={onMouseLeaveHandler}
                  onMouseEnter={changeActiveValue.bind(null, 'value-2')}
                  className={`wrapper-ball__item ${activeValueId === 'value-2' ? 'active-value' : ''}`}
                ></div>
                <div
                  style={{
                    backgroundImage: `url(${ball3})`,
                  }}
                  onMouseLeave={onMouseLeaveHandler}
                  onMouseEnter={changeActiveValue.bind(null, 'value-3')}
                  className={`wrapper-ball__item ${activeValueId === 'value-3' ? 'active-value' : ''}`}
                ></div>
                <div
                  style={{
                    backgroundImage: `url(${ball4})`,
                  }}
                  onMouseLeave={onMouseLeaveHandler}
                  onMouseEnter={changeActiveValue.bind(null, 'value-4')}
                  className={`wrapper-ball__item ${activeValueId === 'value-4' ? 'active-value' : ''}`}
                ></div>
                <div
                  style={{
                    backgroundImage: `url(${ball5})`,
                  }}
                  onMouseLeave={onMouseLeaveHandler}
                  onMouseEnter={changeActiveValue.bind(null, 'value-5')}
                  className={`wrapper-ball__item ${activeValueId === 'value-5' ? 'active-value' : ''}`}
                ></div>
              </div>
              <div
                id="hand"
                style={{
                  backgroundImage: `url(${hand})`,
                }}
              >

              </div>
            </Tilt>
          </div>
        </div>
      </section>
    </section>
  );
};

export default JanariValues;