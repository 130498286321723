import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from "./Layout/Layout";
import HomePage from "./pages/Homepage/HomePage";
import Contact from "./pages/Contact/Contact";
import Blogs from "./pages/Blogs/Blogs";
import OurServices from "./pages/OurServices/OurServices";
import AboutUs from "./pages/AboutUs/AboutUs";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import WhistleBlowing from "./pages/WhistleBlowing/WhistleBlowing";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

const App = () => {

  const [showSplash, setShowSplash] = React.useState(true);

  React.useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setShowSplash(false);
    }, 4000); // Change the duration as needed
  }, []);

  return showSplash ? ( <LoadingScreen/> ) : (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blogs/:id" element={<BlogDetail />} />
        <Route path="/formulir-pengaduan" element={<WhistleBlowing />} />
      </Route>
    </Routes>
  );
};

export default App;