import React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';

import BlogItemCard from "../BlogItemCard/BlogItemCard";

import {ArrowIcon, Dots} from "../../assets/icons";
import API_ENDPOINT from "../../globals/apiEndpoint";

import './BlogsContainer.scss';

const BlogsContainer = ({ isPreview }) => {
  const [blogs, setBlogs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogsResponse = await axios.get(API_ENDPOINT.blogs);
        setBlogs(blogsResponse.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchBlogs();
  }, []);

  const content = isPreview ? blogs.slice(0, 3) : blogs;

  return (
    <section className="blogsHomePage container">
      <header className="d-flex flex-column flex-md-row justify-content-between">
        <h2>We Share for you</h2>
        {isLoading && !isPreview && (
          <h2 className="text-center">Loading...</h2>
        )}
        {isPreview && (
          <div className="blogsHomePage__preview-link">
            <Link to="/blogs" className="btn d-flex justify-content-between blogsHomePage__link">
              <span>See All</span>
              <ArrowIcon />
            </Link>
          </div>
        )}
      </header>
      <div className="blogsHomePage__container row">
        {content.map((blog, index) => (
          <BlogItemCard
            key={index + 1}
            id={index + 1}
            title={blog.title}
            category={blog.category}
            tagLine={blog.subtitle}
            background={blog.image_url}
          />
        ))}
      </div>
      <Dots className="dots"/>
    </section>
  );
};

export default BlogsContainer;