import React from 'react';

import './OurservicesItem.scss';

import { JanariIconItem, SubListIcon } from "../../assets/icons";

const OurServicesItem = ({ position, title, id, paragraph, listItem, background }) => {
  return (
    <section id={id} className={`our-services-item row ${position === 'left' ? 'flex-row' : 'flex-row-reverse'}`}>
      <div className={`our-services-item-description col-11 col-md-6 col-xxl-6 px-lg-5 d-flex flex-column justify-content-center justify-content-lg-center ${position === 'left' ? '': 'is-background'}`}>
        <h3>{title}</h3>
        <div>
          {paragraph}
        </div>
        <ul className="our-services__list-item">
          {listItem.map((item, index) => (
            <li className="our-services__list-item-main" key={index}>
              <JanariIconItem />
              <span className="our-services__list-item-title">{item.title}</span>

              <ul>
                {item.subList.map((subItem, index) => (
                  <li key={index}>
                    <SubListIcon />
                    <span>{subItem}</span>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="our-services-item-background col-6"
        style={{
          backgroundImage: `url(${background})`
        }}
      ></div>
    </section>
  );
};

export default OurServicesItem;