import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import servicesData from "../../data/services";

import {
  ArrowIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WhatsappIcon
} from "../../assets/icons";

import API_ENDPOINT from "../../globals/apiEndpoint";

import './Footer.scss';

const Footer = () => {
  const [socialMedia, setSocialMedia] = React.useState([{
    whatsapp: '',
    facebook: '',
    instagram: '',
    twitter: '',
    email: '',
  }]);

  React.useEffect(() => {
    axios.get(API_ENDPOINT.contact)
      .then(res => {
        setSocialMedia(res.data.data);
      });
  }, []);

  return (
    <section className="footer">
      <section className="footer__header d-flex justify-content-center justify-content-lg-between">
        <div className="footer__header__sosmed d-flex align-items-center justify-content-start justify-content-sm-center justify-content-lg-start">
          <ul className="d-flex gap-2 gap-lg-3 p-0">
            <li>
              <a href={`https://wa.me/62${socialMedia[0].whatsapp.substring(1)}`} target="_blank" rel="noreferrer">
                <WhatsappIcon />
              </a>
            </li>
            <li>
              <a href={socialMedia[0].facebook} target="_blank" rel="noreferrer">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a href={socialMedia[0].twitter} target="_blank" rel="noreferrer">
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a href={socialMedia[0].instagram} target="_blank" rel="noreferrer">
                <InstagramIcon />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__header__contact col-9 d-flex align-items-center justify-content-start justify-content-lg-end">
          <ul className="d-flex justify-content-center mx-auto mx-lg-0 justify-content-sm-end">
            <li>
              <a href={`mailto:${socialMedia[0].email}`} className="d-flex align-items-center justify-content-between">
                <span>information@janariglobal.co.id</span>
                <ArrowIcon />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <hr/>
      <section className="footer__accordion accordion accordion-flush d-block d-sm-none" id="accordionNavigation">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              Services
            </button>
          </h2>
          <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
               data-bs-parent="#accordionNavigation">
            <nav className="accordion-body">
              <ul>
                <li>
                  <Link
                    to={servicesData['service-1'].link}
                    state={{ service: '#social-media-management' }}
                  >Social Media Management</Link>
                </li>
                <li>
                  <Link
                    to={servicesData['service-2'].link}
                    state={{ service: '#crisis-management' }}
                  >Crisis Management</Link>
                </li>
                <li>
                  <Link
                    to={servicesData['service-6'].link}
                    state={{ service: '#it-development' }}
                  >IT Development</Link>
                </li>
                <li>
                  <Link
                    to={servicesData['service-4'].link}
                    state={{ service: '#creative-management' }}
                  >Creative Management</Link>
                </li>
                <li>
                  <Link
                    to={servicesData['service-5'].link}
                    state={{ service: '#event-management' }}
                  >Event Management</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <hr className="d-block d-sm-none"/>
      <section className="footer__navigation-main d-block d-sm-none">
        <nav>
          <ul>
            <li>
              <Link to="/about-us">Company</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/blogs">Blog</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </section>
      <section className="footer__message d-flex flex-column flex-md-row justify-content-between">
        <p>©2023. All Rights Reserved. PT Janari Global Indonesia</p>
        <div>
          <span>Privacy Policy</span> |
          <span> Terms of Use</span> |
          <span> Condition</span>
        </div>
      </section>
    </section>
  )
};

export default Footer;