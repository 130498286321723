import React from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper/core';

import API_ENDPOINT from "../../globals/apiEndpoint";

import './Testimonials.scss';

const Testimonials = () => {
  const [clientData , setClientData] = React.useState([]);

  React.useEffect(() => {
    axios.get(API_ENDPOINT.client)
      .then(res => {
        setClientData(res.data);
      });
  }, []);

  SwiperCore.use([Autoplay]);

  return (
    <section
    >
      <section className="testimonials container">
        <section data-aos="zoom-in-up" className="testimonials__container ">
          <Swiper
            autoplay={true}
            slidesPerView={8}
            spaceBetween={20}
            centeredSlides={true}
            centeredSlidesBounds={true}
            loopFillGroupWithBlank={true}
            speed={500}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
          >
            {clientData.data?.map((client) => (
              <SwiperSlide key={client.id}>
                <div className="d-flex align-items-center swiper-item">
                  <img title={client.client_alt} src={client.client_logo} alt={client.client_alt} loading="lazy"/>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </section>
    </section>
  );
};

export default Testimonials;