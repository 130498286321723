import React from 'react';
import './Contact.scss';

import HeroContact from '../../components/HeroContact/HeroContact';
import FormContact from '../../components/FormContact/FormContact';
import PromotionCard from '../../components/PromotionCard/PromotionCard';
import Footer from "../../components/Footer/Footer";


const Contact = () => {
  return (
    <>
      <HeroContact />
      <FormContact />
      <br />
      <PromotionCard />
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Contact;