import React from 'react';

import './ContentAboutUs.scss';

const ContentAboutUs = () => {
  return (
    <section className="content__aboutUs">
      <div className="content__aboutUs__container container mx-auto row flex-column flex-lg-row g-5 justify-content-center">
        <div className="col-lg-4">
          <h2 className="display-5 fw-bold lh-1">About Us</h2>
        </div>
        <div className="col-12 col-lg-7 mt-3 mt-lg-5">
          <p>
            We are a communication service provider that
            focuses on digital systems using various online and offline platforms,
            to support the creation of an integrated communication system with a high level of security,
            so as to create effective and efficient communication.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContentAboutUs;