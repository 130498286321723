import React from 'react';

import heroAboutUsImage from '../../assets/logo/logoHeroAboutPage.svg';

import './HeroAboutUs.scss';

const HeroAboutUs = () => {
  return (
    <section
      className="hero__aboutUs">
      <div className="hero__aboutUs__container container d-flex align-items-center">
        <img src={heroAboutUsImage} className="hero-about-img d-block mx-auto" alt=""/>
      </div>
    </section>
  );
};

export default HeroAboutUs;