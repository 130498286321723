import React from "react";
import "./LoadingScreen.scss";
import janariLogo from '../../assets/logo/janariLogo.svg';

const LoadingScreen = () => {
	return (
		<div className="spinner-container">
			<div className="effect">
				<img src={janariLogo}  className="img-loading " alt="Janari Logo"/>
			</div>
			<h3>Please Wait</h3>
		</div>
	)
}

export default LoadingScreen;