import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowIcon } from "../../assets/icons";

import './BlogItemCard.scss';

const BlogItemCard = ({ category, title, tagLine, id, background }) => {
  const truncateTitle = title.length > 40 ? title.slice(0, 40) + '...' : title;

  return (
    <article data-aos="fade-right" className="article__body col-12 col-md-6 col-lg-4">
      <div className="article__body-container">
        <section className="article__body-container-main">
          <header>
            <img src={background} alt="blog background"/>
          </header>
          <article>
            <span>{category}</span>
            <h3 title={title}>{truncateTitle}</h3>
          </article>
          <footer>
            <Link to={`/blogs/${id}`} className="btn d-flex justify-content-between align-items-center">
              <span>Read More</span>
              <ArrowIcon />
            </Link>
          </footer>
        </section>
        <section className="article__body-container--hover">
          <div className="article__body-container--hover__content">
            <header>
              <span>{category}</span>
            </header>
            <article>
              <h3 title={title}>{truncateTitle}</h3>
              <p>{tagLine}</p>
            </article>
          </div>
          <footer>
            <Link to={`/blogs/${id}`} className="btn d-flex justify-content-between align-items-center">
              <span>Read More</span>
              <ArrowIcon />
            </Link>
          </footer>
        </section>

      </div>
    </article>
  );
};

export default BlogItemCard;