import React from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';

import { ArrowIcon } from "../../assets/icons";
import './PromotionCard.scss';

const PromotionCard = () => {
  return (
    <Tilt perspective={15000} transitionSpeed={1000}>
      <section className="promotion">
        <div className="promotion__container d-flex mx-auto flex-column flex-lg-row justify-content-center align-items-center container">
          <div>
            <h2>
              <span>Optimize</span> your
              <span> Communication </span>
              with <span>Janari</span>
            </h2>
          </div>
          <div className="d-flex flex-column flex-lg-row align-items-center px-0 px-lg-3">
            <p>
              Join our ambitious team dedicated to betterment and innovation
              at every level. We value expertise, experience, and openness to
              collaborate, push boundaries, and deliver on our promises.
              If you do too, work with us.
            </p>
            <Link to="/services" className="btn"><ArrowIcon /></Link>
          </div>
        </div>
      </section>
    </Tilt>
  );
};

export default PromotionCard;