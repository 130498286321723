import "./HeroWhistleBlowing.scss";

const HeroWhistleBlowing = () => {
    return (
        <>
            <div className="contact__hero">
                <div className="container">
                    <h1 className="contact__hero-text">Formulir Sistem Pengaduan Internal (Whistle Blowing System)</h1>
                </div>
            </div>
        </>
    );
};

export default HeroWhistleBlowing;