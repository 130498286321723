import React from 'react';

import './AboutUs.scss';

import HeroAboutUs from '../../components/HeroAboutUs/HeroAboutUs';
import ContentAboutUs from "../../components/ContentAboutUs/ContentAboutUs";
import MessageAboutUs from "../../components/MessageAboutUs/MessageAboutUs";
import PromotionCard from "../../components/PromotionCard/PromotionCard";
import Testimonials from '../../components/Testimonials/Testimonials';
import JanariValues from "../../components/JanariValues/JanariValues";
import Footer from "../../components/Footer/Footer";

const AboutUs = () => {
  return (
    <section className="about-us__main">
      <HeroAboutUs />
      <ContentAboutUs />
      <MessageAboutUs />
      <Testimonials />
      <div className="about-us__main__janarivalues">
        <JanariValues />
      </div>
      <div className="about-us__main__promotion">
        <PromotionCard/>
      </div>
      <footer>
        <Footer />
      </footer>
    </section>
  );
};

export default AboutUs;