import React from 'react';
import background from '../../assets/background/hero.png';
import backgroundMobile from '../../assets/background/hero_mobile.svg';

import './Hero.scss';

const Hero = () => {
  return (
    <div data-aos="flip-up" className="hero">
      <div className="container">
        <img className="d-none d-sm-block" src={background} alt="Hero background" draggable={false}/>
        <img src={backgroundMobile} className="d-block d-sm-none" alt="hero mobile" draggable={false}/>
      </div>
    </div>
  );
};

export default Hero;