const servicesData = {
  'service-1': {
    title: 'Social Media Management',
    description: `
      <p>
        Social media Communication can manage the dissemination of business and company information, as well as manage the company's image to external audience.
        Social Media Management can help create and manage digital assets for you.
      </p>
    `,
    link: '/services#social-media-management',
  },
  'service-2': {
    title: 'Crisis Management',
    description: `
      <p>
        Crisis management as a preventive measure or strategy to optimize the management of communication crises that occur in your company or business.
      </p>
    `,
    link: '/services#crisis-management',
  },
  'service-3': {
    title: 'Integrated Communication',
    description: `
      <p>
        A communication program integrated and can be a strategy for your company to create integrated internal and external communication.    
      </p>
    `,
    link: '/services#it-development',
  },
  'service-4': {
    title: 'Creative Management',
    description: `
      <p>
        Communication can be more interesting if it is collaborated with good creative management.
        So that Creative Management can produce program results that are more attractive and easy to understand in embodying information through visual forms.
      </p>
   
    `,
    link: '/services#creative-management',
  },
  'service-5': {
    title: 'Event management',
    description: `
      <p>
        Event management can be a solution for you to convey communication in the form of an experience that is in line with your goals.
      </p>
    `,
    link: '/services#event-management',
  },
  'service-6': {
    title: 'IT Development',
    link: '/services#it-development'
  }
};

export default servicesData;