import React from "react";
import axios from 'axios';
import API_ENDPOINT from "../../globals/apiEndpoint";

import "./FormWhistleBlowing.scss";
import { ArrowIcon } from '../../assets/icons';

const FormWhistleBlowing = () => {
    const [isMessageSent, setIsMessageSent] = React.useState(false);
    const [radioLainnya, setRadioLainnya] = React.useState(true);
    const [fileBukti, setFileBukti] = React.useState('');
    const [formData, setFormData] = React.useState({
            email: null,
            nama_pelapor: null,
            no_telp : null,
            alamat_pelapor: null,
            nama_terlapor: null,
            hubungan_terlapor: null,
            pengaduan: null,
            detail_permasalahan : null
    });

    const onHandleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name] : value
        })
    }

    const onHandleRadioLainnyaChange = (e) => {
        setRadioLainnya(false)
    }

    const handleFileChange = (e) => { 
        setFileBukti(e.target.files[0])
    }
    
    const submitHandler = async (event) => {
        event.preventDefault();

        console.log(formData);

        // Create FormData object to handle file upload
        const formDataForApi = new FormData();
        Object.keys(formData).forEach(key => {
            formDataForApi.append(key, formData[key]);
        });

        formDataForApi.append("file_bukti", fileBukti);

        console.log(formDataForApi);

        try {
           await axios.post(API_ENDPOINT.send_pelaporan, formDataForApi, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
           });
            setIsMessageSent(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="container mb-3">
                <div className="d-flex justify-content-between contact-content">
                    <div className="wrapper">
                        <h1 className="font-green font-green__title">Whistle Blowing System</h1>
                        <p className="font-green">
                           
                        </p>
                        <div className="form__container">
                            <form className="form-contact" onSubmit={submitHandler}>
                                {/* name */}
                                <div className="mb-3">
                                    <label className="font-green mb-2">Nama Pelapor</label><br/>
                                    <input onChange={onHandleChange} type="text" name="nama_pelapor" className="custom-form form-control form-control-lg" id="nama_pelapor" aria-describedby="nameHelp" placeholder="Nama Pelapor" value={formData.nama_pelapor} required/>
                                </div>
                                {/* Email */}
                                <div className="mb-3">
                                    <label className="font-green mb-2">Alamat Email</label><br/>
                                    <input onChange={onHandleChange} type="email" name="email"  className="custom-form form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="Alamat Email" value={formData.email} required/>
                                </div>
                                {/* Message */}
                                <div className="mb-3">
                                    <label className="font-green mb-2">No. Telp yang dapat dihubungi (No. WA)</label><br/>
                                    <input onChange={onHandleChange} type="text" name="no_telp" className="custom-form form-control form-control-lg" id="no_telp" aria-describedby="noTelpHelp" placeholder="No. Telp yang dapat dihubungi (No. WA)" value={formData.no_telp} required/>
                                </div>
                                <div className="mb-3">
                                    <label className="font-green mb-2">Alamat Pelapor</label><br/>
                                    <textarea onChange={onHandleChange} type="text" name="alamat_pelapor" className="custom-form form-control form-control-lg" id="alamat_pelapor" aria-describedby="alamatPelaporHelp" placeholder="Alamat Pelapor" value={formData.alamat_pelapor} required></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="font-green mb-2">Nama Terlapor</label><br/>
                                    <input onChange={onHandleChange} type="text" name="nama_terlapor"  className="custom-form form-control form-control-lg" id="nama_terlapor" aria-describedby="namaTerlaporHelp" placeholder="Nama Terlapor" value={formData.nama_terlapor} required/>
                                </div>
                                <div className="mb-3">
                                    <label className="font-green mb-2">Hubungan dengan terlapor</label><br/>
                                    <input onChange={onHandleChange} type="text" name="hubungan_terlapor" className="custom-form form-control form-control-lg" id="hubungan_terlapor" aria-describedby="hubunganTerlaporHelp" placeholder="Hubungan dengan terlapor" value={formData.hubungan_terlapor} required/>
                                </div>
                                <div className="mb-3">
                                <p className="font-green">Jenis Pengaduan</p>
                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Pelanggaran Kode Etik' checked={formData.pengaduan === 'Pelanggaran Kode Etik'} />
                                    <label className="font-green" style={{marginLeft:'10px'}}> Pelanggaran Kode Etik</label><br/>

                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Korupsi/Gratifikasi' checked={formData.pengaduan === 'Korupsi/Gratifikasi'}/>
                                    <label className="font-green" style={{marginLeft:'10px'}}> Korupsi/Gratifikasi</label><br/>
                                    
                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Penyalahgunaan Wewenang' checked={formData.pengaduan === 'Penyalahgunaan Wewenang'} />
                                    <label className="font-green" style={{marginLeft:'10px'}}> Penyalahgunaan Wewenang</label> <br/>

                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="messpengaduanage" aria-describedby="pengaduanHelp" value='Benturan Kepentingan' checked={formData.pengaduan === 'Benturan Kepentingan'} />
                                    <label className="font-green" style={{marginLeft:'10px'}}> Benturan Kepentingan</label> <br/>

                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Bangunan/Sarpras' checked={formData.pengaduan === 'Bangunan/Sarpras'}/>
                                    <label className="font-green" style={{marginLeft:'10px'}}> Bangunan/Sarpras</label><br/>

                                     <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Kekerasan/Bullying' checked={formData.pengaduan === 'Kekerasan/Bullying'} />
                                    <label className="font-green" style={{marginLeft:'10px'}}> Kekerasan/Bullying</label> <br/>

                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Kepegawaian' checked={formData.pengaduan === 'Kepegawaian'} />
                                    <label className="font-green" style={{marginLeft:'10px'}}> Kepegawaian</label> <br/>

                                    <input onChange={onHandleChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp" value='Pungutan Liar' checked={formData.pengaduan === 'Pungutan Liar'} />
                                    <label className="font-green" style={{marginLeft:'10px'}}> Pungutan Liar</label><br/>

                                    <input onChange={onHandleRadioLainnyaChange} name="pengaduan" type="radio" className="custom-form mb-3" id="pengaduan" aria-describedby="pengaduanHelp"/>
                                    <label className="font-green" style={{marginLeft:'10px'}}> Lainnya</label><br/>

                                    <input onChange={onHandleChange} disabled={radioLainnya} name="pengaduan" type="text" className="custom-form form-control form-control-md" id="pengaduan_lainnya" aria-describedby="pengaduanHelp" placeholder="Isi disini"/>
                                </div>
                                <div className="mb-3">
                                    <textarea onChange={onHandleChange} type="text" name="detail_permasalahan" className="custom-form form-control form-control-md" id="detail_permasalahan" aria-describedby="detailPermasalahanHelp" placeholder="Detail Permasalahan" >{formData.detail_permasalahan}</textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="font-green mb-2">Dokumen/Bukti Fisik (.csv .doc .docx .jpg .png .pdf)</label><br/>
                                    <input onChange={(e) => handleFileChange(e)} type="file" id="message"  name="file_bukti" aria-describedby="messageHelp" placeholder="Dokumen/Bukti Fisik"/>
                                </div>
                                <div className="mt-5">
                                    <button type="submit" className="submit btn-success btn d-flex justify-content-between align-items-center">Submit <span>
                                        <ArrowIcon />
                                    </span></button>
                                </div>
                                {isMessageSent && <h3 className="message-successfully mx-12">Message has been sent</h3>}
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default FormWhistleBlowing;