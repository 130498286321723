import React from 'react';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Hero from "../../components/Hero/Hero";
import Testimonials from "../../components/Testimonials/Testimonials";
import Services from "../../components/Services/Services";
import JanariValues from "../../components/JanariValues/JanariValues";
import PromotionCard from "../../components/PromotionCard/PromotionCard";
import BlogsContainer from "../../components/BlogsContainer/BlogsContainer";
import PortofolioContainer from "../../components/PortofolioContainer/PortofolioContainer";
import MessageAboutUs from "../../components/MessageAboutUs/MessageAboutUs";

import './homepage.scss';
import Footer from "../../components/Footer/Footer";

const HomePage = () => {

  return  (
    <section className="homepage__main">
      <Hero />
      <div className="homepage__main__message">
        <MessageAboutUs />
      </div>
      <Testimonials />
      <div className="homepage__main__services">
        <Services />
      </div>
      <div className="homepage__main__portofolio">
        <PortofolioContainer isPreview count={1}/>
      </div>
      <div className="homepage__main__janarivalues">
        <JanariValues />
      </div>
      <div className="homepage__main__promotion">
        <PromotionCard />
      </div>
      <BlogsContainer isPreview count={3} />
      <footer>
        <Footer />
      </footer>
    </section>
  )
};

export default HomePage;