import "./HeroContact.scss";

const HeroContact = () => {
    return (
        <>
            <div className="contact__hero">
                <div className="container">
                    <h1 className="contact__hero-text">Contact</h1>
                </div>
            </div>
        </>
    );
};

export default HeroContact;