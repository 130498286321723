import React from 'react';
import Tilt from "react-parallax-tilt";
import parse from 'html-react-parser';
import { CSSTransition } from "react-transition-group";

import servicesImage from '../../assets/background/services.png';

import servicesData from "../../data/services";

import {
  MarineIcon,
  EventIcon,
  HandIcon,
  LaptopIcon,
  PhoneIcon,
  ArrowIcon
} from "../../assets/icons";

import './Services.scss';
import {Link} from "react-router-dom";

const Services = () => {
  const [activeService, setActiveService] = React.useState({
    ...servicesData['service-1'],
  });

  const [activeServiceId, setActiveServiceId] = React.useState('service-1');
  const [enter, setEnter] = React.useState(true);

  const changeActiveService = (service) => {
    setActiveService({
      ...servicesData[service],
    });
    setEnter(true);
  }

  const onMouseLeaveHandler = () => {
    setEnter(false);
  }

  let content = [];
  for (let i = 1; i <= 9; i++) {
    let classes;
    if (i <= 3) {
      classes = 'first';
    } else if (i <= 6) {
      classes = 'second';
    } else {
      classes = 'third';
    }

    let svgIcon;
    let classesIcon;
    if (i === 2) {
      svgIcon = <PhoneIcon onMouseLeave={onMouseLeaveHandler} onMouseEnter={changeActiveService.bind(null, 'service-1')} />;
    } else if (i === 4) {
      svgIcon = <HandIcon onMouseLeave={onMouseLeaveHandler} onMouseEnter={changeActiveService.bind(null, 'service-2')} />;
    } else if (i === 5) {
      svgIcon = <MarineIcon onMouseLeave={onMouseLeaveHandler} onMouseEnter={changeActiveService.bind(null, 'service-3')} />;
    } else if (i === 6) {
      svgIcon = <LaptopIcon onMouseLeave={onMouseLeaveHandler} onMouseEnter={changeActiveService.bind(null, 'service-4')} />;
    } else if (i === 8) {
      svgIcon = <EventIcon onMouseLeave={onMouseLeaveHandler} onMouseEnter={changeActiveService.bind(null, 'service-5')} />;
    }

    if (
      (activeServiceId === 'service-1' && i === 2) ||
      (activeServiceId === 'service-2' && i === 4) ||
      (activeServiceId === 'service-3' && i === 5) ||
      (activeServiceId === 'service-4' && i === 6) ||
      (activeServiceId === 'service-5' && i === 8)
    ) {
      classesIcon = 'active-service';
    } else {
      classesIcon = '';
    }

    content.push(
      <div key={i} className={`services__wheel-item wheel__item-${classes}`}>
        <div className="content">
          <div className={`content-inside ${classesIcon}`}>
            {/*<MarineIcon />*/}
            {svgIcon}
          </div>
        </div>
      </div>
    )
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      let newActiveServiceId;
      if (activeServiceId === 'service-5') {
        newActiveServiceId = 'service-1';
      } else {
        newActiveServiceId = `service-${parseInt(activeServiceId.split('-')[1]) + 1}`;
      }
      setActiveServiceId(newActiveServiceId);
      setActiveService({
        ...servicesData[newActiveServiceId],
      });
    }, 5000);

    const timeout = setTimeout(() => {
      setEnter(true);
    });

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
      setEnter(false);
    };
  }, [activeServiceId]);

  return (
    <section className="services--bg">
      <section className="services container mx-auto row flex-column-reverse flex-lg-row align-items-center py-5 justify-content-center">
        <div className="px-4 col-10 col-sm-8 col-lg-6 services__description">
          <h2>Our Services</h2>
          <CSSTransition
            in={enter}
            timeout={500}
            classNames="service-transition"
          >
            <div className="services__content">
              <h3>{activeService.title}</h3>
              <div>
                {parse(activeService.description)}
              </div>
              <Link to={activeService.link} className="btn d-flex justify-content-between">
                <span>Explore</span>
                <ArrowIcon />
              </Link>
            </div>
          </CSSTransition>
        </div>
        <div className="col-12 col-lg-6 py-5">
          <Tilt
            perspective={3500}
          >
            <div
              className="services__wheel"
              style={{ backgroundImage: `url(${servicesImage})` }}
            >
              {content}
            </div>
          </Tilt>
        </div>
      </section>
    </section>
  );
};

export default Services;