import React from "react";
import { useParams } from "react-router-dom";
import PromotionCard from "../../components/PromotionCard/PromotionCard";
import ArticleDetail from "../../components/ArticleDetail/ArticleDetail";
import Footer from "../../components/Footer/Footer";
const BlogDetail = () => {
  const { id } = useParams();


  return (
    <>
      <ArticleDetail id={id}/>
      <PromotionCard />
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default BlogDetail;