import React from 'react';
import './WhistleBlowing.scss';

import HeroWhistleBlowing from '../../components/HeroWhistleBlowing/HeroWhistleBlowing';
import FormWhistleBlowing from '../../components/FormWhistleBlowing/FormWhistleBlowing';
import PromotionCard from '../../components/PromotionCard/PromotionCard';
import Footer from "../../components/Footer/Footer";


const WhistleBlowing = () => {
  return (
    <>
      <HeroWhistleBlowing />
      <FormWhistleBlowing />
      <br />
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default WhistleBlowing;