import React from 'react';
import './MessageAboutUs.scss';

const MessageAboutUs = () => {
  return (
    <section data-aos="zoom-in" className="message__aboutUs container text-center d-flex align-items-center">
      <h2>
        Our goal is to expand reach proper dissemination of
        <br />
        information and effective throughout the world
      </h2>
    </section>
  );
};

export default MessageAboutUs;