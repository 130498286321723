import React from 'react';
import axios from 'axios';
import parse from 'html-react-parser'

import API_ENDPOINT from "../../globals/apiEndpoint";

import './ArticleDetail.scss';

const ArticleDetail = ({ id }) => {
  const [article, setArticle] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchArticle = async () => {
      try {
        const articleResponse = await axios.get(API_ENDPOINT.blogs);
        setArticle(articleResponse.data.data[id - 1]);

      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  });

  return (
    <section
      className={`article-detail__container ${isLoading ? 'loading-blog-detail': ''}`}
    >
      {isLoading && !article && (
       <h1>Loading</h1>
      )}
      {article && !isLoading && (
        <>
          <header
            className="article-header d-flex flex-column justify-content-end"
            style={{
              backgroundImage: `url(${article.image_url})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="container">
              <h1>{article.title}</h1>
              <p>{article.subtitle}</p>
            </div>
          </header>
          <div className="blog-detail container">
            {parse(article.body)}
          </div>
        </>
      )}
    </section>
  );
};

export default ArticleDetail;