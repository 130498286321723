const janariData = {
  'value-1': {
    title: 'Trusted',
    description: 'As a communication integration service provider, we have been trusted by various companies to provide communication and information management solutions',
  },
  'value-2': {
    title: 'Effective',
    description: 'We provide professional services with fast time effectiveness with the right results',
  },
  'value-3': {
    title: 'Efficient',
    description: 'Integrated communication can provide efficient service with the right solution and produce satisfactory output.',
  },
  'value-4': {
    title: 'Integrated',
    description: 'Integrated communication can provide efficient service with the right solution and produce satisfactory output.',
  },
  'value-5': {
    title: 'High Security Level',
    description: 'We provide services with server data security, confidentiality, and high management security. So you don\'t have to worry about data security.',
  },
};

export default janariData;